import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Headline from '../../components/atoms/Headline';
import Text from '../../components/atoms/Text';
import Button from '../../components/atoms/Button';
import WhySecureSection from '../../components/organisms/WhySecureSection';
import TheyWorkWithUs from '../../components/organisms/TheyWorkWithUs';

const BeOurPartnerSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const BigHeadline = styled(Headline)`
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  /* font-size: ${({ theme }) => theme.size28}; */
  margin-bottom: 50px;
`;

const Content = styled.div`
  margin-bottom: 50px;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    column-count: 2;
    column-gap: 100px;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 15px;

  p {
    margin-bottom: 15px;
  }
`;

const BeOurPartnerImage = styled.div`
  width: 100%;
`;

const WhyUsSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const WhyEstateSection = styled.section`
  margin: 100px 0;
  padding: 100px 15px;
  background-color: #000;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 100px 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 100px 0;
  }
`;

const WhyEstateContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const WhyEstateContent = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    column-count: 2;
    column-gap: 100px;
  }
`;

const InvestAndEarnPage = () => {
  const imageData = useStaticQuery(query);

  return (
    <MainTemplate
      isMainPage={false}
      title='Inwestuj w nieruchomości i zarabiaj ze mną'
      metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
    deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
    budynkowej i lokalowej)'
    >
      <SubpageHeader headline='Inwestuj w nieruchomości i zarabiaj ze mną' />
      <BeOurPartnerSection>
        <BigHeadline data-aos='headline-fadeup'>
          {imageData.datoCmsInvestAndEarn.firstSectionHeadline}
        </BigHeadline>
        <Content>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: imageData.datoCmsInvestAndEarn.firstSectionText,
            }}
          />
        </Content>
        <BeOurPartnerImage>
          <GatsbyImage
            image={imageData.file.childImageSharp.gatsbyImageData}
            alt='Zarabiaj 20% w skali roku'
            data-aos='headline-fadeup'
          />
        </BeOurPartnerImage>
      </BeOurPartnerSection>
      <WhyUsSection id='whyus-trigger'>
        <Headline
          data-aos='headline-fadeup'
          data-aos-anchor='#whyus-trigger'
          style={{ textAlign: 'center', marginBottom: '50px' }}
        >
          {imageData.datoCmsInvestAndEarn.secondSectionHeadline}
        </Headline>
        <Text
          style={{ marginBottom: '70px' }}
          data-aos='headline-fadeup'
          data-aos-delay='200'
          data-aos-anchor='#whyus-trigger'
          dangerouslySetInnerHTML={{
            __html: imageData.datoCmsInvestAndEarn.secondSectionText,
          }}
        />

        <Button
          text='Kontakt'
          link='/kontakt'
          data-aos='headline-fadeup'
          data-aos-delay='400'
          data-aos-anchor='#whyus-trigger'
        />
      </WhyUsSection>
      <WhyEstateSection id='whyestate-trigger'>
        <WhyEstateContainer>
          <Headline
            data-aos='headline-fadeup'
            data-aos-anchor='#whyestate-trigger'
            style={{ color: '#fff', marginBottom: '30px' }}
          >
            {imageData.datoCmsInvestAndEarn.thirdSectionHeadline}
          </Headline>
          <WhyEstateContent>
            <Text
              style={{ color: '#fff' }}
              data-aos='headline-fadeup'
              data-aos-delay='200'
              data-aos-anchor='#whyestate-trigger'
              dangerouslySetInnerHTML={{
                __html: imageData.datoCmsInvestAndEarn.thirdSectionText,
              }}
            />
          </WhyEstateContent>
        </WhyEstateContainer>
      </WhyEstateSection>
      <WhySecureSection />
      <TheyWorkWithUs />
    </MainTemplate>
  );
};

const query = graphql`
  query InvestAndEarnPageQuery {
    file(name: { eq: "zarabiaj-20" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE)
      }
    }
    datoCmsInvestAndEarn {
      firstSectionText
      firstSectionHeadline
      secondSectionHeadline
      secondSectionText
      thirdSectionHeadline
      thirdSectionText
    }
  }
`;

export default InvestAndEarnPage;
