import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Headline from '../atoms/Headline';
import Text from '../atoms/Text';

const Section = styled.section``;

const ImageWrapper = styled.div`
  width: 100%;
`;

const StyledGatsbyImage = styled(GatsbyImage)``;

const Content = styled.div`
  margin: 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const Numeration = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 868px;
    margin: 0 auto;
  }
`;

const NumerationBox = styled.div`
  margin-bottom: 30px;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 250px;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const BoxNumber = styled.p`
  font-size: 38px;
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size48};
  }
`;

const BoxText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size15};
  }
`;

const WhySecureSection = () => {
  const imageQuery = useStaticQuery(query);
  return (
    <Section>
      <ImageWrapper>
        <StyledGatsbyImage
          image={imageQuery.file.childImageSharp.gatsbyImageData}
          alt='Dlaczego nieruchomości są bezpieczne'
        />
      </ImageWrapper>
      <Content id='whysecure-trigger'>
        <Headline
          style={{ textAlign: 'center', marginBottom: '40px' }}
          data-aos='headline-fadeup'
          data-aos-anchor='#whysecure-trigger'
        >
          {imageQuery.datoCmsEarnWithMe.fourthSectionHeadline}
        </Headline>
        <Text
          style={{
            textAlign: 'center',
            maxWidth: '730px',
            margin: '0 auto 20px auto',
          }}
          data-aos='headline-fadeup'
          data-aos-delay='200'
          data-aos-anchor='#whysecure-trigger'
          dangerouslySetInnerHTML={{
            __html: imageQuery.datoCmsEarnWithMe.fourthSectionText,
          }}
        />
        <Numeration>
          <NumerationBox
            data-aos='headline-fadeup'
            data-aos-delay='400'
            data-aos-anchor='#whysecure-trigger'
          >
            <BoxNumber>1.</BoxNumber>
            <BoxText
              dangerouslySetInnerHTML={{
                __html: imageQuery.datoCmsEarnWithMe.digitCounter[0].text,
              }}
            />
          </NumerationBox>
          <NumerationBox
            data-aos='headline-fadeup'
            data-aos-delay='500'
            data-aos-anchor='#whysecure-trigger'
          >
            <BoxNumber>2.</BoxNumber>
            <BoxText
              dangerouslySetInnerHTML={{
                __html: imageQuery.datoCmsEarnWithMe.digitCounter[1].text,
              }}
            />
          </NumerationBox>
          <NumerationBox
            data-aos='headline-fadeup'
            data-aos-delay='600'
            data-aos-anchor='#whysecure-trigger'
          >
            <BoxNumber>3.</BoxNumber>
            <BoxText
              dangerouslySetInnerHTML={{
                __html: imageQuery.datoCmsEarnWithMe.digitCounter[2].text,
              }}
            />
          </NumerationBox>
        </Numeration>
      </Content>
    </Section>
  );
};

const query = graphql`
  query WhySecureSectionImageQuery {
    file(name: { eq: "why-secure-section" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          formats: WEBP
          placeholder: NONE
        )
      }
    }
    datoCmsEarnWithMe {
      fourthSectionHeadline
      fourthSectionText
      digitCounter {
        text
      }
    }
  }
`;

export default WhySecureSection;
