import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled(Link)`
  width: 233px;
  height: 60px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  font-size: ${({ theme }) => theme.size28};
  font-weight: ${({ theme }) => theme.semiBold};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = ({ text, link }) => {
  return <ButtonWrapper to={link}>{text}</ButtonWrapper>;
};

export default Button;
